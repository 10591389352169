<template>
  <v-app-bar app color="white">
    
    <v-app-bar-nav-icon @click.stop="toggleSidebar()"></v-app-bar-nav-icon>

    <v-toolbar elevation="0" flat tile width="100%" transition="slide-x-transition">

      <v-btn icon v-if="hasBack" @click="onBack()">
        <v-icon small>mdi-less-than</v-icon>
      </v-btn>

      <slot name="title">
         <v-toolbar-title>
          {{title}}
        </v-toolbar-title>
      </slot>

      <v-tabs v-model="currentTab" :exact="true" v-if="tabs" color="#577399">
        <v-tab v-for="tab in tabs" :key="tab.to" :to="tab.to">{{tab.title}}</v-tab>
      </v-tabs>

      <v-spacer></v-spacer>

      <v-btn @click="btn.click ? trigger(btn.click) : ''" class="ml-2 mr-2" v-for="btn in buttons" :key="btn.label" v-bind="btn">{{btn.label}}</v-btn>

    </v-toolbar>
  </v-app-bar>
</template>
<script>
export default {
  name: 'AppBar',
  props: {
    back: {
      type: [String, Boolean]
    },
    title: String,
    tabs: Array,
    buttons: Array,
    value: [Number, String],
    click: String,
  },
  computed: {
    hasBack() {
      if (!this.back) return false
      return true
    },
    drawerStatus() {
      return this.drawer
    },
  },
  data: () => ({
    currentTab: 0
  }),
  mounted() {
    console.log('back', this.back)
  },
  watch: {
    currentTab(val) {
      this.$emit('input', val)
    }
  },
  methods: {
    onBack() {
      if (typeof this.back == 'boolean') {
        this.$emit('onBack')
      } else if(typeof this.back == 'string') {
        this.$router.push(this.back)
      }
    },
    trigger(eventName) {
      console.log(eventName)
      this.$emit(eventName)
    },
    toggleSidebar() {
      this.$root.$emit('toggleSidebar')
    }
  }
}
</script>