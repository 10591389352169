<template>
  <v-snackbar
    v-model="feedback.show"
    :color="feedback.color"
    top
    absolute
    app
  >
  {{feedback.text}}
  <template v-if="feedback.close" v-slot:action="{ attrs }">
    <v-btn
      color="red"
      text
      v-bind="attrs"
      @click="feedback.show = false"
    >
      Close
    </v-btn>
  </template>
</v-snackbar>
</template>
<script>
export default {
  name: 'Feedback',
  props: ['value'],
  data: () => ({
    feedback: {
      show: false,
      close: false,
      text: '',
      color: 'info'
    },
    overlay: false
  }),
  methods: {
    _default(text) {
      this.feedback.text = text
      this.feedback.show = true
    },
    success(text) {
      this.feedback.color = 'success'
      this._default(text)
    },
    error(text) {
      this.feedback.color = 'error'
      if (typeof text == 'undefined') text = 'an error occured.'
      this._default(text)
    },
    info(text) {
      this.feedback.color = 'info'
      this._default(text)
    }
  }
}
</script>